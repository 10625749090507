import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
// import AssessmentApis from './assessmentApis';
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import { useGetAllCommonAdminInitialDataMutation, usePostCommonMutation } from 'slices/get/common';

const validationSchema = yup.object().shape({
  series_list: yup.array().min(1, 'Select at least one series'),
  standard_list: yup.array().max(1, 'Select at least one standard'),
  subject_list: yup.array().max(1, 'Select at least one subject'),
  lesson_list: yup.string().required('Lesson is required'),
  part_list: yup.array().of(
    yup.object().shape({
      part: yup.string().required('Part name is required'),
    })
  ),
  active: yup.string().required('Status is required'),
  excelFile: yup.mixed().required('Excel file is required'),
});

const Control = ({ children, label, ...props }) => {
  return (
    <components.Control {...props} className="form-floating">
      {children}
      <label>{label}</label>
    </components.Control>
  );
};

const ModelAddHomeWork = ({ tableInstance }) => {
  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [getSeriesData] = useGetSeriesDataMutation();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postCommon] = usePostCommonMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  let {
    isOpen,
    onCloseEmptyModal,
    whichModal,
    onCloseAddModal,

    // seriesData,
    // standardData,
    // subjectData,
    // selectedSeries,
    // selectedStandard,
    // selectedSubject,
    // setSelectedSeries,
    // setSelectedStandard,
    // setSelectedSubject,
  } = tableInstance;
  const queryParams = new URLSearchParams(location.search);
  const subjectId = queryParams.get('subjectId');

  const [data, setData] = useState();
  const [seriesData, setSeriesdata] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [subjectData, setSubjectsdata] = useState([]);
  const [lessonData, setLessonData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);
  const [sectionData,setSectionData] = useState([])
  const [assessmentData, setAssessmentData] = useState([]);
  // setAssessmentData({ series: selectedOptions });
  const [requiredFields, setRequiredFields] = useState({
    seriesRequired: false,
    standardsRequired: false,
    subjectsRequired: false,
    lessonsRequired: false,
    schoolRequired: false,
    statusRequired: false,
    dateRequired: false,
    homeworkRequired: false,
    sectionRequired : false
  });

  let assessmentQuetionsData = {
    assessmentId: 10,
    questionAndOptions: [
      { question: '', options: '', answer: '' },
      { question: '', options: '', answer: '' },
    ],
  };
  const access_token = fetchAccessToken();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    // getAllAssessment({ ep: '/assessment/data', token: access_token }).then((response) => {
    //   console.log(response);
    //   console.log('Custom data from after_request:', response);

    //   checkAccessTime(response, currentPath);
    //   setData(response.data.data);
    // });
    getAllCommonAdminInitialData({ ep: '/standard/data', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        let standarOptions = response.data.data.map((each) => {
          if (each.active == 'on') {
            return { value: each.id, label: each.name };
          }
        });
        checkAccessTime(response, currentPath);
        setStandardData(standarOptions);
      }
    });
    getAllCommonAdminInitialData({ ep: '/series/data', token: access_token }).then((response) => {
      if (response.data.data.length > 0) {
        checkAccessTime(response, currentPath);
        let seriesOption = response.data.data.map((each) => ({ value: each.id, label: each.name }));
        setSeriesdata(seriesOption);
      }
    });
    getAllCommonAdminInitialData({ ep: '/subject/data', token: access_token }).then((response) => {
      checkAccessTime(response, currentPath);
      if (response.data.data.length > 0) {
        let subjectsOption = response.data.data.map((each) => ({
          value: each.id,
          label: each.name,
        }));
        setSubjectsdata(subjectsOption);
      }
    });
    getAllCommonAdminInitialData({ ep: '/school/schools_list', token: access_token }).then(
      (response) => {
        if (response.data.data.length > 0) {
          let schoolOptions = response.data.data.map((each) => ({
            value: each.id,
            label: each.name,
          }));
          setSchoolData(schoolOptions);

          checkAccessTime(response, currentPath);
        }
      }
    );
    getAllCommonAdminInitialData({ ep: '/section/section_list', token: access_token }).then(
      (response) => {
        if (response.data.data.length > 0) {
          let sectionOptions = response.data.data.map((each) => ({
            value: each.id,
            label: each.name,
          }));
          setSectionData(sectionOptions);

          checkAccessTime(response, currentPath);
        }
      }
    );
  }, []); // The empty dependency array means the effect runs once after the initial render

  const [excelData, setExcelData] = useState([]);

  const initialValues = {
    series_list: [],
    standard_list: [],
    subject_list: [],
    lesson: '',
    part_list: [{ part: '', live: '', animation: '' }],
    image_list: [{ image: '' }],
    active: '',
    excelFile: null, // Add initial value for the file
  };

  const checkFields = (assessmentData) => {
    const newRequiredFields = _.cloneDeep(requiredFields);
    if (assessmentData.length == 0) {
      newRequiredFields.seriesRequired = true;
    }

    if (assessmentData.series == undefined || assessmentData.series.length == 0) {
      newRequiredFields.seriesRequired = true;
    }
    if (assessmentData.standard == undefined || assessmentData.standard.length == 0) {
      newRequiredFields.standardsRequired = true;
    }
    // if (assessmentData.subjects == undefined || assessmentData.subjects.length == 0) {
    //   newRequiredFields.subjectsRequired = true;
    // }
    if (assessmentData.lessons == undefined || assessmentData.lessons.length == 0) {
      newRequiredFields.lessonsRequired = true;
    }
    if (assessmentData.date == undefined || assessmentData.date.length == 0) {
      newRequiredFields.dateRequired = true;
    }
    if (assessmentData.homework == undefined || assessmentData.homework.length == 0) {
      newRequiredFields.homeworkRequired = true;
    }
    if (assessmentData.school == undefined || assessmentData.school.length == 0) {
      newRequiredFields.schoolRequired = true;
    }
    // if (!assessmentData.totalMarks || assessmentData.totalMarks == undefined) {
    //   newRequiredFields.totalMarksrRequired = true;
    // }
    // if (!assessmentData.passMarks || assessmentData.passMarks == undefined) {
    //   newRequiredFields.passMarksrRequired = true;
    // }
    // if (!assessmentData.noOfQuestions || assessmentData.noOfQuestions == undefined) {
    //   newRequiredFields.noOfQuestionsRequired = true;
    // }
    if (!assessmentData.active || assessmentData.active == undefined) {
      newRequiredFields.statusRequired = true;
    }
    if (assessmentData.section == undefined || assessmentData.section.length == 0) {
      newRequiredFields.sectionRequired = true;
    }
    // if (!assessmentData.name || assessmentData.name == undefined) {
    //   newRequiredFields.assessmentNameRequired = true;
    // }

    setRequiredFields(newRequiredFields);
    if (
      !newRequiredFields.seriesRequired &&
      !newRequiredFields.standardsRequired &&
      // !newRequiredFields.subjectsRequired &&
      !newRequiredFields.dateRequired &&
      !newRequiredFields.schoolRequired &&
      !newRequiredFields.lessonsRequired &&
      // !newRequiredFields.totalMarksrRequired &&
      // !newRequiredFields.passMarksrRequired &&
      // !newRequiredFields.noOfQuestionsRequired &&
      !newRequiredFields.statusRequired &&
      !newRequiredFields.homeworkRequired && !newRequiredFields.sectionRequired
    ) {
      return {
        status: true,
      };
    } else {
      return {
        status: false,
      };
    }
  };

  const onSubmit = (values) => {
    console.log('values......', values);
    // if (!values.excelFile) {
    //   alert('Please upload an Excel file');
    //   return;
    // }
    // console.log(values, excelData); // Use excelData here
    // onCloseAddModal(values);
  };
  const newHandleSubmit = async () => {
    let checkAllRequiredFields = await checkFields(assessmentData);
    if (checkAllRequiredFields.status) {
      // let values = {
      //   name: assessmentData.name,
      //   school_id: assessmentData.school.value,
      //   assessment_type: 5,
      //   lesson_id: assessmentData.lessons.value,
      //   series_id: assessmentData.series.value,
      //   standard_id: assessmentData.standard.value,
      //   subject_id: assessmentData.subjects.value,
      //   no_of_questions: assessmentData.noOfQuestions,
      //   total_marks: assessmentData.totalMarks,
      //   pass_marks: assessmentData.passMarks,
      //   active: assessmentData.active,
      // };

      let values = {
        series_id: assessmentData.series.value,
        school_id: assessmentData.school.value,
        standard_id: assessmentData.standard.value,
        subject_id: subjectId,
        lesson_id: assessmentData.lessons.value,
        section_id : assessmentData.section.value,
        date: assessmentData.date,
        homework: assessmentData.homework,
        active: assessmentData.active,
      };

      postCommon({
        ep: '/standard/home_work_submit',
        values,
        token: access_token,
      }).then((response) => {
        if (response.data.status) {
          setSuccessMessage(response.data);
          setIsSuccess(true);
          setTimeout(() => {
            // onCloseAddModal();
            window.location.reload();
          }, [2000]);
        } else {
          setSuccessMessage(response.data);
          setIsSuccess(true);
        }
      });
    }
    // console.log(response);
    // console.log('post response', response);
    // checkAccessTime(response, currentPath);
    // setStandardData(standarOptions);
  };

  return (
    <>
      {
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log('Submitted values:', values);
            console.log('Selected series list:', values.series_list);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
            <FormikForm onSubmit={newHandleSubmit}>
              <Modal
                backdrop="static"
                keyboard={false}
                className="modal-right fade"
                show={isOpen}
                onHide={onCloseEmptyModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {whichModal.slice(0, 1).toUpperCase()}
                    {whichModal.slice(1)} Homework
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={seriesData}
                      onChange={(selectedOptions) => {
                        requiredFields.seriesRequired = false;
                        console.log('Selected Options:', selectedOptions);

                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            series: selectedOptions, // Update 'series' in state
                          };

                          console.log('Updated assessmentData:', updatedData.series);

                          // Check if standard and subjects are defined
                          if (
                            updatedData.standard !== undefined &&
                            subjectId !== undefined
                          ) {
                            // Assuming series is an array, map through and extract IDs or values
                            // const seriesValues = updatedData.series.map(item => item.value);

                            // Make the API call with the updated data
                            getSeriesData({
                              ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${subjectId}&series_ids=${updatedData.series.value}`,
                              token: access_token,
                            }).then((response) => {
                              if (response.data.data.length > 0) {
                                let lessonOptions = response.data.data.map((each) => {
                                  if (each.active == 'on') {
                                    return { value: each.id, label: each.lesson };
                                  }
                                });
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                            });
                          }

                          return updatedData;
                        });

                        // Optionally update required fields state
                        setRequiredFields({ ...requiredFields });
                      }}
                      value={assessmentData.series}
                      placeholder="Select Series"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />

                    {requiredFields.seriesRequired && (
                      <div className="d-block invalid-tooltip">
                        Please select at least one series
                      </div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      onChange={(selectedOptions) => {
                        requiredFields.schoolRequired = false;
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          school: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={assessmentData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      onChange={(selectedOptions) => {
                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            standard: selectedOptions, // Update 'series' in state
                          };

                          // Check if standard and subjects are defined
                          if (
                            updatedData.series != undefined &&
                            subjectId != undefined
                          ) {
                            getSeriesData({
                              ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${subjectId}&series_ids=${updatedData.series.value}`,
                              token: access_token,
                            }).then((response) => {
                              if (response.data.data.length > 0) {
                                let lessonOptions = response.data.data.map((each) => {
                                  if (each.active == 'on') {
                                    return { value: each.id, label: each.lesson };
                                  }
                                });
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                            });
                          }

                          return updatedData;
                        });

                        requiredFields.standardsRequired = false;

                        setRequiredFields(requiredFields);
                      }}
                      value={assessmentData.standard}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.standardsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select standard</div>
                    )}
                  </div>
                  {/* <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={subjectData}
                      onChange={(selectedOptions) => {
                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            subjects: selectedOptions, // Update 'series' in state
                          };

                          console.log('Updated assessmentData:', updatedData.series);

                          // Check if standard and subjects are defined
                          if (
                            updatedData.series != undefined &&
                            updatedData.standard != undefined
                          ) {
                            getSeriesData({
                              ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${updatedData.subjects.value}&series_ids=${updatedData.series.value}`,
                              token: access_token,
                            }).then((response) => {
                              if (response.data.data.length > 0) {
                                let lessonOptions = response.data.data.map((each) => {
                                  if (each.active == 'on') {
                                    return { value: each.id, label: each.lesson };
                                  }
                                });
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                            });
                          }

                          return updatedData;
                        });

                        requiredFields.subjectsRequired = false;
                        // setAssessmentData({'standard' : selectedOptions})
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          subjects: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={assessmentData.subjects}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.subjectsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select subject</div>
                    )}
                  </div> */}
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={sectionData}
                      onChange={(selectedOptions) => {
                        
                        requiredFields.sectionRequired = false;
                       
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          section: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={assessmentData.section}
                      placeholder="Select Section"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.sectionRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select Section</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      onChange={(selectedOptions) => {
                        // console.log("selectedOptions....",selectedOptions);
                        // setFieldValue('series_list', selectedOptions);
                        // requiredFields.seriesRequired = false
                        requiredFields.lessonsRequired = false;
                        // setAssessmentData({'standard' : selectedOptions})
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          lessons: selectedOptions, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                      value={assessmentData.lessons}
                      placeholder="Select Lesson"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.lessonsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select lesson</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <DatePicker
                      selected={
                        assessmentData.date
                          ? new Date(assessmentData.date.replace(/\//g, '-'))
                          : null
                      }
                      onChange={(date) => {
                        if (date) {
                          const formattedDate = format(date, 'yyyy/MM/dd');
                          setAssessmentData((prevData) => ({
                            ...prevData,
                            date: formattedDate,
                          }));
                          requiredFields.dateRequired = false;
                          setRequiredFields({ ...requiredFields });
                        }
                      }}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select Date"
                      className="form-control"
                    />
                    {requiredFields.dateRequired && (
                      <div className="d-block invalid-tooltip">Please select a date</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <textarea
                      style={{ height: '100px' }}
                      className="form-control"
                      name="homework"
                      placeholder="Enter homework details here"
                      value={assessmentData.homework || ''}
                      onChange={(event) => {
                        const homeworkValue = event.target.value;
                        setAssessmentData((prevData) => ({
                          ...prevData,
                          homework: homeworkValue,
                        }));

                        requiredFields.homeworkRequired = false;
                        setRequiredFields({ ...requiredFields });
                      }}
                    />
                    {requiredFields.homeworkRequired && (
                      <div className="d-block invalid-tooltip">Please enter homework</div>
                    )}
                  </div>
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={assessmentData.active === 'on'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={assessmentData.active === 'off'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false;
                        setAssessmentData((prevData) => ({
                          ...prevData, // Spread the existing state
                          active: event.target.value, // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields);
                      }}
                    />
                    {requiredFields.statusRequired == true && (
                      <div className="d-block invalid-tooltip">Please select status</div>
                    )}
                  </div>
                </Modal.Body>

                {isSuccess && (
                  <div
                    className={`card mb-3 ${
                      successMessage.status ? 'bg-success' : 'bg-danger'
                    } text-white`} // Dynamically set the background color based on success or failure
                    style={{ marginTop: '5px' }}
                  >
                    <div className="card-body">
                      <p className="card-text">{successMessage.message}</p>
                    </div>
                  </div>
                )}
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={newHandleSubmit}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </FormikForm>
          )}
        </Formik>
      }
    </>
  );
};

export default ModelAddHomeWork;
