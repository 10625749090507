import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Formik, FieldArray, Form as FormikForm, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
// import AssessmentApis from './assessmentApis';
import * as XLSX from 'xlsx';
import {
  useGetAllAssessmentMutation,
  usePostEditAssessmentMutation,
  usePostAssessmentMutation,
  useGetSeriesDataMutation,
  useGetApiDataMutation,
} from 'slices/get/assessment';
import useService from 'views/default/useService';
import { useLocation } from 'react-router-dom';
import _, { values } from 'lodash';
import { useGetAllCommonAdminInitialDataMutation } from 'slices/get/common';

const validationSchema = yup.object().shape({
  series_list: yup.array().min(1, 'Select at least one series'),
  standard_list: yup.array().max(1, 'Select at least one standard'),
  subject_list: yup.array().max(1, 'Select at least one subject'),
  lesson_list: yup.string().required('Lesson is required'),
  part_list: yup.array().of(
    yup.object().shape({
      part: yup.string().required('Part name is required'),
    })
  ),
  active: yup.string().required('Status is required'),
  excelFile: yup.mixed().required('Excel file is required'),
});

const Control = ({ children, label, ...props }) => {
  return (
    <components.Control {...props} className="form-floating">
      {children}
      <label>{label}</label>
    </components.Control>
  );
};

const ModalAddAssessment = ({ tableInstance }) => {
  const [getAllAssessment] = useGetAllAssessmentMutation();
  const [getSeriesData] = useGetSeriesDataMutation();
  const [getAllCommonAdminInitialData] = useGetAllCommonAdminInitialDataMutation();
  const [postAssessment] = usePostAssessmentMutation();
  const [postEditAssessment] = usePostEditAssessmentMutation();
  const { checkAccessTime, fetchAccessToken } = useService();
  const location = useLocation();
  const currentPath = location.pathname;
  let {
    isOpen,
    onCloseEmptyModal,
    whichModal,
    onCloseAddModal,

    // seriesData,
    // standardData,
    // subjectData,
    // selectedSeries,
    // selectedStandard,
    // selectedSubject,
    // setSelectedSeries,
    // setSelectedStandard,
    // setSelectedSubject,
  } = tableInstance;
 

  const [data, setData] = useState();
  const [seriesData,setSeriesdata] = useState([])
  const [standardData,setStandardData] = useState([])
  const [subjectData,setSubjectsdata] = useState([])
  const [lessonData,setLessonData] = useState([])
  const [schoolData,setSchoolData] = useState([])
  const [assessmentData,setAssessmentData] = useState([])
  // setAssessmentData({ series: selectedOptions });
  const [requiredFields,setRequiredFields] =  useState({
    seriesRequired  : false,
    standardsRequired : false,
    subjectsRequired : false,
    lessonsRequired : false,
    schoolRequired : false,
    totalMarksrRequired : false,
    passMarksrRequired : false,
    noOfQuestionsRequired : false,
    statusRequired : false,
    assessmentNameRequired : false
  })

  
  let assessmentQuetionsData = {
    assessmentId: 10,
    questionAndOptions: [
      { question: '', options: '', answer: '' },
      { question: '', options: '', answer: '' },
    ],
  };
  const access_token = fetchAccessToken();
  const [successMessage, setSuccessMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false)
 

  useEffect(() => {
    

    // getAllAssessment({ ep: '/assessment/data', token: access_token }).then((response) => {
    //   console.log(response);
    //   console.log('Custom data from after_request:', response);

    //   checkAccessTime(response, currentPath);
    //   setData(response.data.data);
    // });
    getAllCommonAdminInitialData({ ep: '/standard/data', token: access_token }).then((response) => {
     if(response.data.data.length > 0){
      let standarOptions = response.data.data.map((each)=>{
        if(each.active == "on"){
          return { value: each.id, label : each.name }
        }
      })
      checkAccessTime(response, currentPath);
      setStandardData(standarOptions);
     }
      
    });
    getAllCommonAdminInitialData({ ep: '/series/data', token: access_token }).then((response) => {
      if(response.data.data.length > 0){
        checkAccessTime(response, currentPath);
        let seriesOption = response.data.data.map(each=>({ value: each.id, label : each.name }))
        setSeriesdata(seriesOption);
      } 
    });
    getAllCommonAdminInitialData({ ep: '/subject/data', token: access_token }).then((response) => {
      checkAccessTime(response, currentPath);
      if(response.data.data.length > 0){
        let subjectsOption = response.data.data.map(each=>({ value: each.id, label : each.name }))
        setSubjectsdata(subjectsOption);
      }
      
    });
    getAllCommonAdminInitialData({ ep: '/school/schools_list', token: access_token }).then((response) => {
      if(response.data.data.length > 0){
        let schoolOptions = response.data.data.map((each)=>({ value: each.id, label : each.name }))
        setSchoolData(schoolOptions);

        checkAccessTime(response, currentPath);
      }
      
      
    });
    
    
  }, []); // The empty dependency array means the effect runs once after the initial render

  const [excelData, setExcelData] = useState([]);
  const [handleFile,sethandleFile] = useState(true)
  

  const initialValues = {
    series_list: [],
    standard_list: [],
    subject_list: [],
    lesson: '',
    part_list: [{ part: '', live: '', animation: '' }],
    image_list: [{ image: '' }],
    active: '',
    excelFile: null, // Add initial value for the file
  };

  const handleFileUpload = (event, setFieldValue) => {
    const file = event.target.files[0];
    console.log('file.....', file);
    // console.log("file.....",)
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const data = new Uint8Array(e.target.result);
    //     // console.log('xlData.....',data)
    //     const workbook = XLSX.read(data, { type: 'array' });
    //     const sheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[sheetName];
    //     const jsonData = XLSX.utils.sheet_to_json(worksheet);
    //     setExcelData(jsonData); // Store Excel data
    //   };
    //   reader.readAsArrayBuffer(file);
    //   setFieldValue('excelFile', file);
    //   console.log('excelData.........',excelData)
    // }
    if (file) {
      const reader = new FileReader();
      setIsSuccess(false)
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
    
        // Standard column names
        const standardColumns = ['question', 'option1', 'option2', 'option3', 'option4', 'answer'];
        const actualColumns = Object.keys(jsonData[0] || {}); // Get column names from the file
        // Check for missing or extra columns
        const missingColumns = standardColumns.filter(col => !actualColumns.includes(col));
        const extraColumns = actualColumns.filter(col => !standardColumns.includes(col));
    
        if (missingColumns.length > 0) {
          // alert(`Error: Missing columns - ${missingColumns.join(', ')}`);
          sethandleFile(false)
          setIsSuccess(true)
          setSuccessMessage({
            status : false,
            message : `Error: Missing columns - ${missingColumns.join(', ')}`
          }
        
          )
          return; // Stop processing
        }
    
        if (extraColumns.length > 0) {
          // alert(`Error: Extra columns detected - ${extraColumns.join(', ')}`);
          sethandleFile(false)
          setIsSuccess(true)
          setSuccessMessage({
            status : false,
            message : `Error: Extra columns detected - ${extraColumns.join(', ')}`
          }
           
          )
          return; // Stop processing
        }
    
        // Check for empty values in standard columns
        const emptyFields = [];
        jsonData.forEach((row, rowIndex) => {
          standardColumns.forEach((col) => {
            if (!row[col] || row[col].toString().trim() === '') {
              emptyFields.push(`Row ${rowIndex + 1}, Column "${col}"`);
            }
          });
        });
    
        if (emptyFields.length > 0) {
          // alert(`Error: The following fields are empty:\n${emptyFields.join('\n')}`);
           // alert(`Error: Extra columns detected - ${extraColumns.join(', ')}`);
           sethandleFile(false)
           setIsSuccess(true)
           setSuccessMessage(
            {status : false,
              message : `Error: The following fields are empty:\n${emptyFields.join('\n')}`
            }
            
          )
          return; // Stop processing
        }
    
        setExcelData(jsonData); // Store valid Excel data
      };
      reader.readAsArrayBuffer(file);
      setFieldValue('excelFile', file);
    }
  };
  const checkFields = (assessmentData) =>{
    const newRequiredFields = _.cloneDeep(requiredFields)
    if(assessmentData.length == 0){
      newRequiredFields.seriesRequired = true
    }

    if(assessmentData.series == undefined || assessmentData.series.length == 0){
      newRequiredFields.seriesRequired = true
    }
    if(assessmentData.standard == undefined || assessmentData.standard.length == 0){
      newRequiredFields.standardsRequired = true
    }
    if(assessmentData.subjects == undefined || assessmentData.subjects.length == 0){
      newRequiredFields.subjectsRequired = true
    }
    if(assessmentData.lessons == undefined || assessmentData.lessons.length == 0){
      newRequiredFields.lessonsRequired = true
    }
    if(assessmentData.school == undefined || assessmentData.school.length == 0){
      newRequiredFields.schoolRequired = true
    }
    if(!assessmentData.totalMarks || assessmentData.totalMarks == undefined){
      newRequiredFields.totalMarksrRequired = true
    }
    if(!assessmentData.passMarks || assessmentData.passMarks == undefined){
      newRequiredFields.passMarksrRequired = true
    }
    if(!assessmentData.noOfQuestions || assessmentData.noOfQuestions == undefined){
      newRequiredFields.noOfQuestionsRequired = true
    }
    if(!assessmentData.active || assessmentData.active == undefined){
      newRequiredFields.statusRequired = true
    }
    if(!assessmentData.name || assessmentData.name == undefined){
      newRequiredFields.assessmentNameRequired = true
    }
    
    setRequiredFields(newRequiredFields)
    if(!newRequiredFields.seriesRequired && !newRequiredFields.standardsRequired && !newRequiredFields.subjectsRequired && !newRequiredFields.lessonsRequired
      && !newRequiredFields.schoolRequired && !newRequiredFields.totalMarksrRequired && !newRequiredFields.passMarksrRequired && !newRequiredFields.noOfQuestionsRequired 
      && !newRequiredFields.statusRequired && !newRequiredFields.assessmentNameRequired
    ){
      return {
        status : true
      }
    }else{
      return {
        status : false
      }
    }

    
  }

  const onSubmit = (values) => {
    console.log('values......', values);
    // if (!values.excelFile) {
    //   alert('Please upload an Excel file');
    //   return;
    // }
    // console.log(values, excelData); // Use excelData here
    // onCloseAddModal(values);
  };
  const newHandleSubmit = async() => {
    let checkAllRequiredFields = await checkFields(assessmentData)
    if(checkAllRequiredFields.status && handleFile){
      let values = {
        name: assessmentData.name ,
        school_id: assessmentData.school.value,
        assessment_type : 5,
        lesson_id: assessmentData.lessons.value,
        series_id: assessmentData.series.value,
        standard_id: assessmentData.standard.value,
        subject_id: assessmentData.subjects.value,
        no_of_questions: assessmentData.noOfQuestions,
        total_marks: assessmentData.totalMarks,
        pass_marks: assessmentData.passMarks,
        active: assessmentData.active,
      };
      console.log('euiiuewewiuewi',values)
      postAssessment({ ep: '/assessment/assessment_names',values, token: access_token }).then((response) => {
        if(response.data.status && excelData.length > 0){
          
          let quetionsObject =  excelData.map((each)=>{
            let exceldataObject = {
              // "question_type": each.question_type ? String(each.question_type) : "",
              "question": each.question ? String(each.question) : "" ,
              "option1": each.option1 ? String(each.option1) : "",
              "option2": each.option2 ? String(each.option2) : "",
              "option3": each.option3 ? String(each.option3) : "",
              "option4": each.option4 ? String(each.option4) : "",
              "answer": each.answer ? String(each.answer) : "",
              "assessment_id": response.data.data[0].id
            }
            return exceldataObject
          })
          let values = {
            questions : quetionsObject 
          }

          postAssessment({ ep: '/assessment/create_assessment_questions',values, token: access_token }).then((response) => {
            if(response.data.status){
              setSuccessMessage(response.data)
              setIsSuccess(true)
              setTimeout(()=>{
                // onCloseAddModal();
                window.location.reload()
              },[2000])
            }else{
              setSuccessMessage(response.data)
              setIsSuccess(true)
            }
            
            
          });
          
          
        }
        // console.log(response);
        // console.log('post response', response);
        // checkAccessTime(response, currentPath);
        // setStandardData(standarOptions);
      });
    }
   
  };

  return (
    <>
      {
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log('Submitted values:', values);
            console.log('Selected series list:', values.series_list);
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue, handleBlur, values }) => (
            <FormikForm onSubmit={newHandleSubmit}>
              <Modal
                backdrop="static"
                keyboard={false}
                className="modal-right fade"
                show={isOpen}
                onHide={onCloseEmptyModal}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    {whichModal.slice(0, 1).toUpperCase()}
                    {whichModal.slice(1)} Assessment
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={seriesData}
                      onChange={(selectedOptions) => {
                        requiredFields.seriesRequired = false;
                        console.log('Selected Options:', selectedOptions);

                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            series: selectedOptions, // Update 'series' in state
                          };

                          console.log('Updated assessmentData:', updatedData.series);

                          // Check if standard and subjects are defined
                          if (updatedData.standard !== undefined && updatedData.subjects !== undefined) {
                            // Assuming series is an array, map through and extract IDs or values
                            // const seriesValues = updatedData.series.map(item => item.value);

                            // Make the API call with the updated data
                            getSeriesData({
                              ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${updatedData.subjects.value}&series_ids=${updatedData.series.value}`,
                              token: access_token,
                            }).then((response) => {
                              if(response.data.data.length > 0){
                                let lessonOptions = response.data.data.map((each)=>{
                                  if(each.active == "on"){
                                    return { value: each.id, label : each.lesson }
                                  }
                                })
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                              
                            });
                          }

                          return updatedData;
                        });

                        // Optionally update required fields state
                        setRequiredFields({ ...requiredFields });
                      }}
                      value={assessmentData.series}
                      placeholder="Select Series"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />

                    {requiredFields.seriesRequired && (
                      <div className="d-block invalid-tooltip">Please select at least one series</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      onChange={(selectedOptions) => {
                        requiredFields.schoolRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'school' : selectedOptions        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      onChange={(selectedOptions) => {
                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            standard: selectedOptions, // Update 'series' in state
                          };


                          // Check if standard and subjects are defined
                          if(updatedData.series != undefined  && updatedData.subjects != undefined){
                            getSeriesData({ ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${updatedData.subjects.value}&series_ids=${updatedData.series.value}`, token: access_token }).then((response) => {
                              if(response.data.data.length > 0){
                                let lessonOptions = response.data.data.map((each)=>{
                                  if(each.active == "on"){
                                    return { value: each.id, label : each.lesson }
                                  }
                                })
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                              
                              
                            });
                          }

                          return updatedData;
                        });
                        
                        requiredFields.standardsRequired = false
                        
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.standard}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.standardsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select standard</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={subjectData}
                      onChange={(selectedOptions) => {
                        setAssessmentData((prevData) => {
                          const updatedData = {
                            ...prevData,
                            subjects: selectedOptions, // Update 'series' in state
                          };

                          console.log('Updated assessmentData:', updatedData.series);

                          // Check if standard and subjects are defined
                          if(updatedData.series != undefined  && updatedData.standard != undefined){
                            getSeriesData({ ep: `/lesson/get_lessons?standard_id=${updatedData.standard.value}&subject_id=${updatedData.subjects.value}&series_ids=${updatedData.series.value}`, token: access_token }).then((response) => {
                              if(response.data.data.length > 0){
                                let lessonOptions = response.data.data.map((each)=>{
                                  if(each.active == "on"){
                                    return { value: each.id, label : each.lesson }
                                  }
                                })
                                checkAccessTime(response, currentPath);
                                setLessonData(lessonOptions);
                              }
                              
                              
                            });
                          }

                          return updatedData;
                        });
                       
                        requiredFields.subjectsRequired = false
                        // setAssessmentData({'standard' : selectedOptions})
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'subjects' : selectedOptions        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.subjects}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.subjectsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select subject</div>
                    )}
                  </div>
                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      onChange={(selectedOptions) => {
                        // console.log("selectedOptions....",selectedOptions);
                        // setFieldValue('series_list', selectedOptions);
                        // requiredFields.seriesRequired = false
                        requiredFields.lessonsRequired = false
                        // setAssessmentData({'standard' : selectedOptions})
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'lessons' : selectedOptions        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.lessons}
                      placeholder="Select Lesson"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.lessonsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select lesson</div>
                    )}
                  </div>
                  {/* <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      onChange={(selectedOptions) => {
                        requiredFields.lessonsRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'lessons' : selectedOptions        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.lessons}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.lessonsRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select lesson</div>
                    )}
                  </div> */}
                  {/* <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={schoolData}
                      onChange={(selectedOptions) => {
                        requiredFields.schoolRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'school' : selectedOptions        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      value={assessmentData.school}
                      placeholder="Select School"
                      components={{ Control: (props) => <Control {...props} /> }}
                    />
                    {requiredFields.schoolRequired == true && (
                      <div className="d-block invalid-tooltip">Plese select school</div>
                    )}
                  </div> */}
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      // type="number"
                      name="Assessment Name"
                      placeholder="Assessment Name"
                      value={values.name}
                      onChange={(event) => {
                        requiredFields.assessmentNameRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'name' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                        // setFieldValue('totalMarks', event.target.value);
                      
                      // isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Assessment Name</label>
                    {/* {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">Please enter total marks</div>
                    )} */}
                    {requiredFields.assessmentNameRequired == true && (
                      <div className="d-block invalid-tooltip">Please enter assessment name</div>
                    )}

                    {/* <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" /> */}
                  </Form.Floating>
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Total Marks"
                      value={values.totalMarks}
                      onChange={(event) => {
                        requiredFields.totalMarksrRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'totalMarks' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                        // setFieldValue('totalMarks', event.target.value);
                      
                      // isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Total Marks</label>
                    {/* {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">Please enter total marks</div>
                    )} */}
                    {requiredFields.totalMarksrRequired == true && (
                      <div className="d-block invalid-tooltip">Please enter total marks</div>
                    )}

                    {/* <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" /> */}
                  </Form.Floating>
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.passMarks}
                      onChange={(event) => {
                        requiredFields.passMarksrRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'passMarks' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      // isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Pass Marks</label>

                    {requiredFields.passMarksrRequired == true && (
                      <div className="d-block invalid-tooltip">Please enter pass marks</div>
                    )}

                    <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" />
                  </Form.Floating>
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.questions}
                      onChange={(event) => {
                        requiredFields.noOfQuestionsRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'noOfQuestions' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                      // isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">No Of Questions</label>

                    {requiredFields.noOfQuestionsRequired == true && (
                      <div className="d-block invalid-tooltip">Please enter no of questions</div>
                    )}

                    {/* <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" /> */}
                  </Form.Floating>

                  
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={assessmentData.active === 'on'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'active' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={assessmentData.active === 'off'}
                      onChange={(event) => {
                        // setFieldValue('active', event.target.value);
                        requiredFields.statusRequired = false
                        setAssessmentData(prevData => ({
                          ...prevData,               // Spread the existing state
                          'active' : event.target.value        // Add/update key-value pair for 'date'
                        }));
                        setRequiredFields(requiredFields)
                      }}
                    />
                    {requiredFields.statusRequired == true && (
                      <div className="d-block invalid-tooltip">Please select status</div>
                    )}
                  </div>
                  {/* <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={standardData}
                      onChange={(selectedOptions) => {
                        setFieldValue('standard_list', [selectedOptions]);
                        setSelectedStandard(selectedOptions);
                      }}
                      value={values.standard_list[0]}
                      placeholder="Select Standard"
                      components={{ Control: (props) => <Control {...props}  /> }}
                    />
                    {errors.standard_list && touched.standard_list && (
                      <div className="d-block invalid-tooltip">{errors.standard_list}</div>
                    )}
                  </div>

                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={subjectData}
                      onChange={(selectedOptions) => {
                        setFieldValue('subject_list', [selectedOptions]);
                        setSelectedSubject(selectedOptions);
                      }}
                      value={values.subject_list[0]}
                      placeholder="Select Subject"
                      components={{ Control: (props) => <Control {...props}  /> }}
                    />
                    {errors.subject_list && touched.subject_list && (
                      <div className="d-block invalid-tooltip">{errors.subject_list}</div>
                    )}
                  </div>

                  <div className="mb-3 position-relative tooltip-end-top">
                    <Select
                      cacheOptions={false}
                      classNamePrefix="react-select"
                      options={lessonData}
                      onChange={(selectedOptions) => {
                        setFieldValue('lesson_list', [selectedOptions]);
                        setSelectedSubject(selectedOptions);
                      }}
                      value={values.lesson_list[0]}
                      placeholder="Select Lesson"
                      components={{ Control: (props) => <Control {...props}  /> }}
                    />
                    {errors.lesson_list && touched.lesson_list && (
                      <div className="d-block invalid-tooltip">{errors.lesson_list}</div>
                    )}
                  </div>

              <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="text"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.lesson}
                      onChange={(event) => {
                        setFieldValue('lesson', event.target.value);
                      }}
                      isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Assessment Name</label>

                    {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">{errors.lesson}</div>
                    )}

                    <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" />
                  </Form.Floating>

                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.totalMarks}
                      onChange={(event) => {
                        setFieldValue('totalMarks', event.target.value);
                      }}
                      isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Total Marks</label>

                    {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">{errors.lesson}</div>
                    )}

                    <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" />
                  </Form.Floating>
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.passMarks}
                      onChange={(event) => {
                        setFieldValue('passMarks', event.target.value);
                      }}
                      isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">Pass Marks</label>

                    {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">{errors.lesson}</div>
                    )}

                    <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" />
                  </Form.Floating>
                  <Form.Floating className="mb-3 tooltip-end-top">
                    <Form.Control
                      id="floatingInputCustom"
                      type="number"
                      name="Lesson"
                      placeholder="Enter Lesson Name"
                      value={values.questions}
                      onChange={(event) => {
                        setFieldValue('questions', event.target.value);
                      }}
                      isInvalid={errors.part_list && touched.part_list}
                    />
                    <label htmlFor="floatingInputCustom">No Of Questions</label>

                    {errors.lesson && touched.lesson && (
                      <div className="d-block invalid-tooltip">{errors.lesson}</div>
                    )}

                    <ErrorMessage name="Lesson" component="div" className="invalid-tooltip" />
                  </Form.Floating>

                  
                  <div className="mb-3 filled tooltip-end-top">
                    <Form.Label>Status</Form.Label>
                    <Form.Check
                      type="radio"
                      label="On"
                      value="on"
                      id="statusRadio1"
                      name="active"
                      checked={values.active === 'on'}
                      onChange={(event) => {
                        setFieldValue('active', event.target.value);
                      }}
                    />
                    <Form.Check
                      type="radio"
                      label="Off"
                      value="off"
                      id="statusRadio2"
                      name="active"
                      checked={values.active === 'off'}
                      onChange={(event) => {
                        setFieldValue('active', event.target.value);
                      }}
                    />
                    {errors.active && touched.active && (
                      <div className="d-block invalid-tooltip">{errors.active}</div>
                    )}
                  </div> */}
                  
                  <div className="mb-3 tooltip-end-top">
                    <Form.Label>Upload Excel File</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={(event) => handleFileUpload(event, setFieldValue)}
                      isInvalid={errors.excelFile && touched.excelFile}
                    />
                    {errors.excelFile && touched.excelFile && (
                      <div className="d-block invalid-tooltip">{errors.excelFile}</div>
                    )}
                  </div>
                </Modal.Body>
                {/* {successMessage && (
                        <div
                          style={{
                            color: isSuccess ? 'green' : 'red', // Green for success, red for failure
                            marginTop: '10px',
                          }}
                        >
                          {successMessage}
                        </div>
                      )} */}
                       {isSuccess && (
                <div
                  className={`card mb-3 ${
                    successMessage.status ? 'bg-success' : 'bg-danger'
                  } text-white`} // Dynamically set the background color based on success or failure
                  style={{ marginTop: '5px' }}
                >
                  <div className="card-body">
                    <p className="card-text">{successMessage.message}</p>
                  </div>
                </div>
              )}
                <Modal.Footer>
                  <Button variant="outline-primary" onClick={onCloseEmptyModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={newHandleSubmit}>
                    Add
                  </Button>
                </Modal.Footer>
              </Modal>
            </FormikForm>
          )}
        </Formik>
      }
    </>
  );
};

export default ModalAddAssessment;
